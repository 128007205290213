header {
  grid-column: 1 / -1;
  grid-row: 1;
  display: grid;
  grid-template-columns: 1;
  grid-template-rows: 2;
}

.myPhoto {
  margin: 30px auto;
  height: 115px;
  width: 115px;
  border-radius: 9999px;
}

.link-container {
  grid-row: 2;
  display: flex;
  justify-content: center;
}

.link {
  text-decoration: none;
  padding: 0 10px;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-row: 1 / span 2;
  }

  .myPhoto {
    margin: 30px 0;
    grid-row: 1;
  }

  .link {
    display: flex;
    padding: 0 50px;
  }

  .link-container {
    display: flex;
    justify-content: space-between;
  }
}
