.icon-border {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  border: 3px solid #dbdbdb;
  width: 50vw;
  margin: 20px;
  height: 120px;
}

.icon {
  height: 100px;
  width: 100px;
}

.slider {
  margin: 0 auto;
}

@media (min-width: 768px) {
  .background {
    display: flex;
    justify-content: center;
    background-color: #171717;
    border-radius: 40px;
    padding: 15px;
    box-shadow: 0px 0px 30px rgba(219, 219, 219, 0.45);
  }

  .icon {
    height: 48px;
    width: 48px;
  }

  .icon-border {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 72px;
    border-radius: 9999px;
    border: 3px solid #dbdbdb;
    margin: 0 5px;
  }

  .slider {
    grid-column: 1 / -1;
    grid-row: 7 / span 2;
  }
}
