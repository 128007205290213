@import url("/src/css/satoshi.css");

html,
body {
  font-family: "Satoshi-Light";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1c1c1c;
  color: #dbdbdb;
  margin: 0 auto;
  overflow-x: hidden;
}

a,
p {
  font-family: "Satoshi-Light";
}

a {
  color: #dbdbdb;
  font-size: 1.5rem;
}

h1,
h2,
h3 {
  font-family: "Satoshi-Bold";
  margin: 0;
}

h1,
h2 {
  color: white;
}

.app {
  display: grid;
  gap: 50px;
}

@media (min-width: 768px) {
  .app {
    margin: 0 20%;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(10, 1fr);
    height: 100vh;
    gap: 15px;
  }
}
