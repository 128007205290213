/* .welcome {
  grid-column: 2 / span 4;
  grid-row: 2;
  text-align: center;
} */

.welcome {
  grid-row: 2;
  text-align: center;
}

.welcome h1 {
  font-size: 3rem;
}

.welcome h2 {
  font-size: 1.5rem;
}

.welcome h3 {
  font-size: 1.5rem;
}

@media (min-width: 768px) {
  .welcome {
    grid-column: 1 / -1;
    grid-row: 4 / span 3;
  }
  .welcome h1 {
    font-size: 4rem;
  }
  .welcome h2 {
    font-size: 3rem;
  }
  .welcome h3 {
    font-size: 2rem;
  }
}
